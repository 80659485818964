import React, { useState } from "react";
import { Link, useIntl, FormattedMessage, FormattedHTMLMessage, navigate } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";
import AppLayout from "../../components/shared/AppLayout";
import AppTabChanger from "../../components/shared/AppTabChanger";
import planStore from "../../store/planStore";
import hotaiLogo from "../../assets/images/page-plans/hotai-logo.png";
import deliveryIcon from "../../assets/images/page-plans/delivery-icon.svg";
import freeBatteryIcon from "../../assets/images/page-plans/free-battery-icon.svg";
import phoneReplacementIcon from "../../assets/images/page-plans/phone-replacement-icon.svg";
import costOfRepairIcon from "../../assets/images/page-plans/cost-of-repair-icon.svg";
import AppReminderModal from "../../components/shared/AppReminderModal";

const PagePlans = () => {
    const intl = useIntl();
    const messages = intl.messages;
    const tabKeys = ["plans.premiumPlanTab", "plans.litePlanTab"];
    const [activeTab, setActiveTab] = useState(tabKeys[0]);
    const [showReminder, setShowReminder] = useState(true);
    const isSelectedPremiumPlan = activeTab === tabKeys[0];
    const numberOfTableBodyKeys = isSelectedPremiumPlan ? 15: 20;
    const numberOfTableBodyColPerRow = isSelectedPremiumPlan ? 3 : 4;
    const numberOfTableBodyRows = numberOfTableBodyKeys / numberOfTableBodyColPerRow;
    const numberOfTableBodyWatchesRows = 2;
    let currentKeyIterationNo =  -1;
    let currentWatchesKeyIterationNo =  -1;
    const reminderObj = {
        heading: messages["plans.remindertMessageData.title"],
        message: messages["plans.remindertMessageData.message"],
        btnText: messages["plans.remindertMessageData.cta.close"],
    };

    const samsungCarePremiumPlanContent = {
        plansCoverageServices: [
            {title: "plans.premiumPlanCoverages.services.0.title", desc: "plans.premiumPlanCoverages.services.0.desc", iconSrc: deliveryIcon},
            {title: "plans.premiumPlanCoverages.services.1.title", desc: "plans.premiumPlanCoverages.services.1.desc", iconSrc: freeBatteryIcon},
        ],
        plansCoverageAssurance: [
            {title: "plans.premiumPlanCoverages.assurance.0.title", desc: "plans.premiumPlanCoverages.assurance.0.desc", iconSrc: costOfRepairIcon},
            {title: "plans.premiumPlanCoverages.assurance.1.title", desc: "plans.premiumPlanCoverages.assurance.1.desc", iconSrc: phoneReplacementIcon},
        ],
        plansTerms:
            <div className="plans__terms">
                <p>註1<br/>(1) 到府收送件服務僅提供用戶於正常使用產品下之免費到府收送件服務，不包含配件送修。<br/>(2) 僅提供維修服務，不負責用戶資料之救援與保管，請自行儲存備用資料。因維修服務過程中資料可能被開啟，請自行移除機密資料。 <br/>(3) 請先自行移除保護膜等非原廠配件，如前述非原廠配件未經移除或無法移除，本公司不負保管及復原責任。</p>
                <p>註2<br/>需繳滿18個月保費後且無解約之情事，消費者可於購機後18~24個月期間致電Samsung Care+客服中心申請後可至原廠指定維修中心免費更換電池乙次。</p>
                <p>註3<br/>(1) 遭受竊盜、搶奪及強盜事故導致承保之行動裝置整機滅失時，請於事故發生48小時內向警察機關報案並領取報案三聯單(需註明IMEI，案件類別為竊盜、搶奪、強盜)，進線Samsung Care+客服申請後續服務。<br/>(2) 新機係指相同款式之新機本體，不含盒裝及配件，若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代。</p>
                <p>
                    <b>保險注意事項</b><br/>
                    (1) 消費者投保前應審慎瞭解本保險商品之承保範圍、除外不保事項及產品風險。<br/>
                    (2) 保險主要保障項目為意外毀損時之原機維修或置換或因竊盜、搶奪或強盜所致整機滅失時之置換新機，保險期間每年最多理賠3次，未用完不得遞延或折現；理賠方式由保險公司認定，若因毀損判定置換新機時，用戶需先支付自負額，保險公司再以相同款式之新品置換之；若同機款停產、缺貨或其他原因致保險公司無法取得相同機款時，保險公司有權以類似規格之新品替代之。若因竊盜、搶奪或強盜所致整機滅失時之置換，用戶需先支付自負額，以新品置換之。<br/>
                    (3) 未提供理賠所需相關文件，將不適用本專案理賠條件。<br/>
                    (4) 本專案為一個保險商品、二項服務含免費到府收送及無條件免費更換電池，保險商品相關規定請參考保險注意事項，服務部分由三星指定台灣保特科技有限公司執行，本服務不得要求折換現金亦不得將更換電池資格轉讓或轉售予他人。三星保有隨時修改或終止本服務最終解釋權。<br/>
                    (5)「Samsung Care+」之行動裝置保險係由保特保險經紀人股份有限公司代理與銷售，由和泰產物保險股份有限公司承保。<br/>
                    <span style={{ color: "red" }}>(6) 消費者於購買前，應詳閱各種銷售文件內容，本商品之預定費用率(預定附加費用率)最高為48%，最低為48%，如要詳細了解其它相關資訊，請洽本公司業務員、服務據點 (免付費電話：<a href="tel: 0800-880-550">0800-880-550</a>)或網站(網址 - <a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a>)，以保障您的權益。免費申訴電話：<a href="tel: 0800-501-888">0800-501-888</a>，地址：台北市中山區松江路126號13樓。</span><br/>
                    <span style={{ color: "red" }}>(7) 資訊公開查詢 - <a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a> 消費者可至本公司總、分支機構、網址查閱或索取資訊公開說明文件。</span><br/>
                    (8) 保險商品受財團法人保險安定基金之保障。<br/>
                    (9) 本簡介內容、保險費率、保險給付、保險理賠與其他未盡事宜，悉依保單條款規定為準，本簡介之內容<strong style={{ textDecoration: "underline" }}>僅供參考</strong>，和泰產險保留最終承保與否之權利。<br/>
                    <span style={{ color: "red" }}>(10) 和泰產物行動裝置保險 (Samsung Care+ 一次交付型適用) 111.3.14 (111)和泰產商品字第125833號函備查。</span><br/>
                    (11) 和泰產物行動裝置保險(Samsung Care+ Lite一次交付型適用) 111.3.14 (111)和泰產商品字第125835號函備查。
                </p>
                <img className="plans__hotai-logo img-fluid" src={hotaiLogo} alt="" />
            </div>
    };

    const samsungCareLitePlanContent = {
        plansCoverageServices: [
            {title: "plans.litePlanCoverages.services.0.title", desc: "plans.litePlanCoverages.services.0.desc", iconSrc: deliveryIcon},
        ],
        plansCoverageAssurance: [
            {title: "plans.litePlanCoverages.assurance.0.title", desc: "plans.litePlanCoverages.assurance.0.desc", iconSrc: costOfRepairIcon},
        ],
        plansTerms:
            <div className="plans__terms">
                <p>註1<br/>(1) 到府收送件服務僅提供用戶於正常使用產品下之免費到府收送件服務，不包含配件送修。<br/>(2) 僅提供維修服務，不負責用戶資料之救援與保管，請自行儲存備用資料。因維修服務過程中資料可能被開啟，請自行移除機密資料。 <br/>(3) 請先自行移除保護膜等非原廠配件，如前述非原廠配件未經移除或無法移除，本公司不負保管及復原責任。</p>
                <p>註2<br/>(1) 新機係指相同款式之新機本體，不含盒裝及配件，若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代。</p>
                <p>
                    <b>保險注意事項</b><br/>
                    (1) 消費者投保前應審慎瞭解本保險商品之承保範圍、除外不保事項及產品風險。<br/>
                    (2) 保險主要保障項目為意外毀損時之原機維修或置換新機，保險期間每年最多理賠3次，未用完不得遞延或折現；理賠方式由保險公司認定，若因毀損判定置換新機時，用戶需先支付自負額，保險公司再以相同款式之新品置換之；若同機款停產、缺貨或其他原因致保險公司無法取得相同機款時，保險公司有權以類似規格之新品替代之。<br/>
                    (3) 未提供理賠所需相關文件，將不適用本專案理賠條件。<br/>
                    (4) 本專案為一個保險商品和一項免費到府收送服務，保險商品相關規定請參考保險注意事項，服務部分由三星指定台灣保特科技有限公司執行，本服務不得要求折換現金亦不得將更換電池資格轉讓或轉售予他人。三星保有隨時修改或終止本服務最終解釋權<br/>
                    (5)「Samsung Care+」之行動裝置保險係由保特保險經紀人股份有限公司代理與銷售，由和泰產物保險股份有限公司承保。<br/>
                    <span style={{ color: "red" }}>(6) 消費者於購買前，應詳閱各種銷售文件內容，本商品之預定費用率(預定附加費用率)最高為48%，最低為48%，如要詳細了解其它相關資訊，請洽本公司業務員、服務據點 (免付費電話：<a href="tel: 0800-880-550">0800-880-550</a>)或網站(網址 - <a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a>)，以保障您的權益。免費申訴電話：<a href="tel: 0800-501-888">0800-501-888</a>，地址：台北市中山區松江路126號13樓。</span><br/>
                    <span style={{ color: "red" }}>(7) 資訊公開查詢 - <a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a> 消費者可至本公司總、分支機構、網址查閱或索取資訊公開說明文件。</span><br/>
                    (8) 保險商品受財團法人保險安定基金之保障。<br/>
                    (9) 本簡介內容、保險費率、保險給付、保險理賠與其他未盡事宜，悉依保單條款規定為準，本簡介之內容<strong style={{ textDecoration: "underline" }}>僅供參考</strong>，和泰產險保留最終承保與否之權利。<br/>
                    <span style={{ color: "red" }}>(10) 和泰產物行動裝置保險 (Samsung Care+ 一次交付型適用) 111.3.14 (111)和泰產商品字第125833號函備查。</span><br/>
                    (11) 和泰產物行動裝置保險(Samsung Care+ Lite一次交付型適用) 111.3.14 (111)和泰產商品字第125835號函備查。
                </p>
                <img className="plans__hotai-logo img-fluid" src={hotaiLogo} alt="" />
            </div>
    };

    const selectedPlanContents = {
        plansCoverageServices: isSelectedPremiumPlan ? samsungCarePremiumPlanContent.plansCoverageServices : samsungCareLitePlanContent.plansCoverageServices,
        plansCoverageAssurance: isSelectedPremiumPlan ? samsungCarePremiumPlanContent.plansCoverageAssurance : samsungCareLitePlanContent.plansCoverageAssurance,
        tableHeaderSmartPhoneData: isSelectedPremiumPlan ? "plans.table.premiumPlan.header.smartPhone" : "plans.table.litePlan.header.smartPhone",
        tableHeaderWatchesData: isSelectedPremiumPlan ? "plans.table.premiumPlan.header.watches" : "plans.table.litePlan.header.watches",
        tableBodySmartPhoneData: isSelectedPremiumPlan ? "plans.table.premiumPlan.body.smartPhone" : "plans.table.litePlan.body.smartPhone",
        tableBodyWatchesData: isSelectedPremiumPlan ? "plans.table.premiumPlan.body.watches" : "plans.table.litePlan.body.watches",
        tableDisclamer:  isSelectedPremiumPlan ? "plans.table.premiumPlan.disclaimer" : "plans.table.litePlan.disclaimer",
        plansTerms: isSelectedPremiumPlan ? samsungCarePremiumPlanContent.plansTerms : samsungCareLitePlanContent.plansTerms,
    };

    const onHandleChangeTab = (tabKey) => {
        setActiveTab(tabKey);
    }

    const goToImeiPage = () => {
        navigate(constants.ROUTES.IMEI);
    };

    const handleSubmit = () => {
        planStore.updatePlanType(isSelectedPremiumPlan ? constants.PLAN.PREMIUM : constants.PLAN.LITE);
        goToImeiPage();
    }

    const hideReminder = () => {
        setShowReminder(false);
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>

            {showReminder && (
                <AppReminderModal
                    messageData={reminderObj}
                    close={hideReminder}
                />
            )}

            <div className="app-page page-plans">

                <div className="plans">

                    <div className="container">

                            <div className="plans__return-link">
                                {messages["plans.return.text"] === "" ?
                                null
                                : ( <FormattedMessage id="plans.return.text" /> )}
                                <Link to={constants.ROUTES.RETURN} rel="noreferrer">{messages["plans.return.link"]}</Link>
                            </div>

                        <div className="plans__tab-changer">
                            <p className="plans__tab-changer-title text-bold">
                                <FormattedMessage id="plans.title" />
                            </p>
                            <AppTabChanger tabKeys={tabKeys} onChangeTab={onHandleChangeTab} />
                        </div>

                        <div className="plans__details">
                            <ul className="plans__coverage-services-list">
                                <li className="plans__list-header text-bold">
                                    <FormattedMessage id="plans.coverageServices" />
                                </li>
                                {
                                    selectedPlanContents.plansCoverageServices.map(( item, index ) => {
                                        const key = `${ item.name }-${ index }`;

                                        return (
                                            <li className="plans__list-item" key={ key }>
                                                <img className="plans__list-icon" alt="" src={item.iconSrc} />
                                                <div className="plans__list-content">
                                                    <p className="plans__list-text text-bold">
                                                        <FormattedMessage id={item.title} />
                                                    </p>
                                                    <p className="plans__list-description">
                                                        <FormattedMessage id={item.desc} />
                                                    </p>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ul>

                            <ul className="plans__coverage-assurance-list">
                                <li className="plans__list-header text-bold">
                                    <FormattedMessage id="plans.coverageAssurance" />
                                </li>
                                {
                                    selectedPlanContents.plansCoverageAssurance.map(( item, index ) => {
                                        const key = `${ item.name }-${ index }`;

                                        return (
                                            <li className="plans__list-item" key={ key }>
                                                <img className="plans__list-icon" alt="" src={item.iconSrc} />
                                                <div className="plans__list-content">
                                                    <p className="plans__list-text text-bold">
                                                        <FormattedMessage id={item.title} />
                                                    </p>
                                                    <p className="plans__list-description">
                                                        <FormattedMessage id={item.desc} />
                                                    </p>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ul>

                            <div className="table-responsive">
                                <table className="plans__table table table-bordered">
                                    <thead>
                                        <tr>
                                            <th rowSpan="1" colSpan="1">
                                                <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderSmartPhoneData}.0.subject`} />
                                            </th>
                                            <th rowSpan="1" colSpan="3">
                                                <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderSmartPhoneData}.1.subject`} />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th rowSpan="1" colSpan="1">
                                                <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderSmartPhoneData}.2.subject`} />
                                            </th>
                                            <th rowSpan="1" colSpan="1">
                                                <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderSmartPhoneData}.3.subject`} />
                                            </th>
                                            <th rowSpan="1" colSpan="1">
                                                <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderSmartPhoneData}.4.subject`} />
                                            </th>
                                            {!isSelectedPremiumPlan &&
                                                <th rowSpan="1" colSpan="1">
                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderSmartPhoneData}.5.subject`} />
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            [...Array( numberOfTableBodyRows ).keys()].map(( row, rowIndex ) => {
                                                const key = `${ row }-${ rowIndex }`;
                                                const renderRows = () => {
                                                    return (
                                                        <tr  key={ key }>
                                                            {
                                                                [...Array( numberOfTableBodyColPerRow )].map(( col, index ) => {
                                                                    const key = `${ col }-${ index }`;
                                                                    currentKeyIterationNo = currentKeyIterationNo + 1;
                                                                    const renderRowCells = () => {
                                                                        return (
                                                                            <td rowSpan="1" colSpan="1" key={ key }>
                                                                                <FormattedHTMLMessage id={`${selectedPlanContents.tableBodySmartPhoneData}.${currentKeyIterationNo}.subject`} />
                                                                            </td>
                                                                        );
                                                                    };

                                                                    return renderRowCells();
                                                                })
                                                            }
                                                        </tr>
                                                    );
                                                };

                                                return renderRows();
                                            })
                                        }
                                    </tbody>
                                </table>

                                {isSelectedPremiumPlan &&
                                    <table className="plans__table table table-bordered">
                                        <thead>
                                            <tr>
                                                <th rowSpan="1" colSpan="1">
                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderWatchesData}.0.subject`} />
                                                </th>
                                                <th rowSpan="1" colSpan="3">
                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderWatchesData}.1.subject`} />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th rowSpan="1" colSpan="1">
                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderWatchesData}.2.subject`} />
                                                </th>
                                                <th rowSpan="1" colSpan="1">
                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderWatchesData}.3.subject`} />
                                                </th>
                                                <th rowSpan="1" colSpan="1">
                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderWatchesData}.4.subject`} />
                                                </th>
                                                {!isSelectedPremiumPlan &&
                                                    <th rowSpan="1" colSpan="1">
                                                        <FormattedHTMLMessage id={`${selectedPlanContents.tableHeaderWatchesData}.5.subject`} />
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                [...Array( numberOfTableBodyWatchesRows ).keys()].map(( row, rowIndex ) => {
                                                    const key = `${ row }-${ rowIndex }`;
                                                    const renderRows = () => {
                                                        return (
                                                            <tr  key={ key }>
                                                                {
                                                                    [...Array( numberOfTableBodyColPerRow )].map(( col, index ) => {
                                                                        const key = `${ col }-${ index }`;
                                                                        currentWatchesKeyIterationNo = currentWatchesKeyIterationNo + 1;

                                                                        const renderRowCells = () => {
                                                                            return (
                                                                                <td rowSpan="1" colSpan="1" key={ key }>
                                                                                    <FormattedHTMLMessage id={`${selectedPlanContents.tableBodyWatchesData}.${currentWatchesKeyIterationNo}.subject`} />
                                                                                </td>
                                                                            );
                                                                        };

                                                                        return renderRowCells();
                                                                    })
                                                                }
                                                            </tr>
                                                        );
                                                    };

                                                    return renderRows();
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>

                            <p className="plans__table-remark">
                                <FormattedHTMLMessage id={selectedPlanContents.tableDisclamer} />
                            </p>

                        </div>

                        <p className="btn-container btn-container--center">
                            <button className="btn btn-lg btn-primary" type="submit" onClick={handleSubmit} disabled={true}>
                                <FormattedMessage id="plans.cta" />
                            </button>
                        </p>

                        {selectedPlanContents.plansTerms}

                        <p className="plans__learn-more">
                            <Link to={constants.ROUTES.FAQ} rel="noreferrer">
                                <FormattedMessage id="plans.learnMore" />
                            </Link>
                        </p>

                    </div>

                </div>

            </div>

        </AppLayout>
    )

}

export default PagePlans
