import React, { Fragment, useState } from "react";
import { FormattedMessage, navigate } from "gatsby-plugin-intl";
import * as constants from "../../app.constant";

const AppTabChanger = ({ tabKeys, onChangeTab }) => {
    const initialTab = tabKeys[0];
    const [activeTab, setActiveTab] = useState(initialTab);

    const onHandleSelectOption = ( tabKey ) => {
        setActiveTab( tabKey );
        onChangeTab( tabKey );
    }

    return (
        <Fragment>
            <div className="app-tab-changer">
                {
                    tabKeys.map(( tabKey, index ) => {
                        const key = `${ tabKey }-${ index }`;
                        const active = tabKey === activeTab ? "app-tab-changer__tab-button--is-active text-bold" : "";

                        return (
                            <div className="app-tab-changer__tab" key={ key } >
                                <button className={`app-tab-changer__tab-button ${ active }`} type="button" onClick={() => onHandleSelectOption( tabKey )}>
                                    <FormattedMessage id={tabKey} />
                                </button>
                            </div>
                        );
                    
                    })
                }
                <div className="app-tab-changer__tab">
                    <button className={"app-tab-changer__tab-button"} type="button" onClick={() => navigate(constants.ROUTES.CUSTOMER_COLLECTION)}>
                        <FormattedMessage id={"plans.eventTab"} />
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default AppTabChanger;
